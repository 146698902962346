var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Search.style';
var Search = function Search(_ref) {
  var className = _ref.className;
  return __jsx("div", {
    className: "nva-search ".concat(className)
  }, __jsx("form", {
    className: "nva-search__form"
  }, __jsx("div", {
    className: "nva-search__input-wrapper"
  }, __jsx("input", {
    className: "nva-search__input u-button",
    type: "text",
    placeholder: "Search",
    "aria-label": "Search"
  }), __jsx("button", {
    className: "nva-search__submit",
    "aria-label": "Search"
  }, __jsx("svg", {
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    height: 24,
    width: 24
  }, __jsx("path", {
    fill: "currentColor",
    d: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
  }))))));
};
export default styled(Search).withConfig({
  componentId: "sc-17igek7-0"
})(["", ";"], styles);